
import { Vue, Component } from 'vue-property-decorator';
import { getEncData } from '@/api/nice';
import { checkUserId, findPassword } from '@/api/user';

@Component({
  name: 'FindPassword',
})
export default class extends Vue {
  mounted() {
    this.getEncData();
  }

  private formData = {
    email: '',
    name: '',
    phone: '',
  };

  private encData = '';

  private getEncData() {
    getEncData().then((res) => {
      this.encData = res.data;
    });
  }

  private handleClickNice() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        checkUserId(this.formData.email).then((res) => {
          if (res.data) {
            const nicePopup = window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            (this.$refs.niceForm as HTMLFormElement).action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
            (this.$refs.niceForm as HTMLFormElement).target = 'popupChk';
            (this.$refs.niceForm as HTMLFormElement).submit();

            const loop = setInterval(() => {
              if (nicePopup && nicePopup.closed) {
                clearInterval(loop);
                if ((document.getElementById('actualName') as any).value && (document.getElementById('phone') as any).value) {
                  this.formData.name = (document.getElementById('actualName') as any).value;
                  this.formData.phone = (document.getElementById('phone') as any).value;
                  const loading = this.$loading.show();
                  findPassword(this.formData).then(() => {
                    alert('비밀번호 재설정 메일이 발송되었습니다.');
                    this.$router.push('/');
                    loading.hide();
                  }).catch((error) => {
                    loading.hide();
                    alert(error.response.data.message);
                  });
                }
              }
            }, 400);
          } else {
            alert('존재하지 않는 사용자입니다.');
          }
        });
      }
    });
  }
}
